$phone: 320px;
$tablet: 500px;
$laptop: 1200px;
$desktop: 1400px;
$large-display: 1920px;

@mixin getFontSize($size) {
  $result: $size;
  @media only screen and (min-width: $laptop) {
    $result: $size * 1.5;
    font-size: $result;
  }
  @media only screen and (max-width: $laptop) {
    $result: $size * 1.25;
    font-size: $result;
  }
  @media only screen and (max-width: $tablet) {
    $result: $size * 1.25;
    font-size: $result;
  }
  @media only screen and (max-width: $phone) {
    $result: $size * 1.25;
    font-size: $result;
  }
}

@mixin getMenuItemSize() {
  @media only screen and (min-width: $laptop) {
    height: 10%;
    width: 10%;
  }
  @media only screen and (max-width: $laptop) {
    height: 20%;
    width: 20%;
  }
  @media only screen and (max-width: $tablet) {
    height: 40%;
    width: 40%;
  }
  @media only screen and (max-width: $phone) {
    height: 40%;
    width: 40%;
  }
}


@mixin getImageOrientation() {
  @media screen and (orientation:landscape) {
    height: 100%;
  }

  @media screen and (orientation:portrait) {
    width: 100%;
  }
}
