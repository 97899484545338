// Fonts
@import url("https://fonts.googleapis.com/css?family=Nunito");

// Variables
@import "variables";

// Mixins
@import "mixins";

// Bootstrap
// @import "react-bootstrap";

// Custom fonts
@font-face {
  font-family: "Iglesia-font";
  src: url("fonts/Iglesia.ttf");
}

* {
  margin: 0;
  padding: 0;
}


$breakpoint-tablet: 1400px;
$main_bg_color: #454d55;

html,
body {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-color: #fff;
  color: #343434;
  font-size: 10pt;
}

#root {
  height: 100%;
  max-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .card-title {
    font-family: "Iglesia-font", sans-serif;
    text-align: center;
    background-color: #fff;
    @include getFontSize(2.2em);
  }

  .card-subtitle {
    margin: 0.2em auto;
    text-indent: 1em;
    @include getFontSize(0.8em);
  }

  #content {
    flex: 1 1 auto;
    max-height: 100%;
    height: 100%;
    width: 100%;
    overflow: auto;

    .background {
      min-height: 100%;
      background-image: url("../assets/images/virgen-mini.webp");
      background-repeat: no-repeat;
      background-size: auto;
      background-position: center;
      background-position-y: 0;
    }

    @media (min-width: $breakpoint-tablet) {
      div.flex-row {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;

        div.column {
          flex: 1 1 auto;
        }
      }
    }

    .leaflet-container {
      height: 650px;
    }

    .carousel-inner {
      align-items: center;
      display: flex;
      height: 100%;

      .carousel-item {
        background-color: #333;
        height: 100%;
        text-align: center;

        img {
          @include getImageOrientation();
        }
      }

    }

    .full-height {
      height: 100%;
      background: #fff;
      color: #434343;
      min-height: 100%;
      padding-left: 2em;
      padding-right: 2em;
      overflow-x: auto;

      &.carousel {
        padding-left: 0;
        padding-right: 0;
      }

      &.contacto {
        min-height: 100%;

        .card-body {
          margin-bottom: 100px;
        }
      }
    }

    .news {
      .new {
        .card-subtitle {
          margin: 15px 0;
          font-weight: bold;
        }
        .hora {
          text-decoration: underline;
        }
        p {
          text-indent: 2em;
        }
      }
    }
  }

  .navbar-bottom {
    background-color: #fff;
    width: 100%;
    z-index: 1000;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-evenly;

    a {
      padding: 1em;
      background: rgba(255, 255, 255, 0.5);

      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      border: 2px solid transparent;

      &:hover {
        border: 2px solid #fff;
      }

      &.selected {
        svg {
          color: $grey;
        }
      }

      svg {
        color: $greyed;
        @include getMenuItemSize();
      }

    }

  }

}